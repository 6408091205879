import { Select, Tooltip } from '@chakra-ui/react';
import { Select as MultiSelect } from 'chakra-react-select';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const SelectInput = forwardRef(
  (
    {
      options,
      value,
      isNumber,
      onChange,
      placeholder,
      isMulti,
      isDisabled,
      enableSearch,
      isComplexOptions,
      isClearable,
      showValueTooltip,
      ...props
    },
    ref
  ) => {
    if (!options) {
      return [];
    }
    const createOptions = () => {
      return options.map((option) => {
        return (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        );
      });
    };

    const isChakraSelect = isMulti || enableSearch || isComplexOptions;

    const getValue = () => {
      if (value === undefined || value === null) {
        return null;
      }

      if (isChakraSelect) {
        return options.filter((option) => value.includes(option.value));
      }

      return value;
    };

    const formatValue = (value) => {
      return isNumber ? Number(value) : value;
    };

    let attr = {
      ref,
      value: getValue(),
      isDisabled,
      placeholder: placeholder || '',
      fontSize: 'sm',
      color: 'secondaryGray.600',
      ...props,
    };

    if (isChakraSelect) {
      return (
        <MultiSelect
          {...attr}
          isMulti={isMulti}
          options={options}
          useBasicStyles
          isClearable={isClearable}
          onChange={(selected) => {
            const value = isMulti
              ? selected.map((item) => formatValue(item.value))
              : formatValue(selected?.value);
            onChange(value);
          }}
          chakraStyles={{
            option: (prev, state) => ({
              ...prev,
              fontSize: 'sm',
            }),
            multiValueLabel: (prev, { selectProps }) => ({
              ...prev,
              fontWeight: props.fontWeight,
              p: 0,
            }),
          }}
        ></MultiSelect>
      );
    }

    return (
      <Tooltip
        label={
          showValueTooltip && value
            ? options.find((option) => option.value === value)?.label
            : ''
        }
        placement="top"
      >
        <Select
          {...attr}
          onChange={(event) => onChange(formatValue(event.target.value))}
          sx={{
            color:
              !attr.value && !!placeholder ? 'gray.400' : 'secondaryGray.900',
            fontWeight: !attr.value && !!placeholder ? 400 : props.fontWeight,
          }}
        >
          {createOptions()}
        </Select>
      </Tooltip>
    );
  }
);

SelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]),
  isNumber: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  enableSearch: PropTypes.bool,
  isComplexOptions: PropTypes.bool,
  isClearable: PropTypes.bool,
  showValueTooltip: PropTypes.bool,
};

export default SelectInput;
