export const checkboxStyles = {
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          _disabled: {
            borderColor: 'inherit',
          },
        },
      },
    },
  },
};
